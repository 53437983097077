import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Link from "../utils/link"

const Header = () => {
  const [isVisible, setIsVisible] = React.useState(false)

  const toggleIsVisible = () => {
    setIsVisible(!isVisible)
  }

  return (
    <header className="header">
      <div className="header__container">
        <Link to="/" title="Site Name" className="header__logo">
          <StaticImage src="../assets/images/logo.png" alt="logo" />
        </Link>
      </div>
    </header>
  )
}

export default Header
